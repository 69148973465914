import { combineReducers } from 'redux';
import orders from './orders';
import buyer from './buyer';
import product from './product';
import report from './report';
import goods from './goods';
import buyReport from './buyReport';
import customer from './customer';
import dashboard from './dashboard';

const rootReducer = combineReducers({
    orders,
    buyer,
    product,
    report,
    goods,
    buyReport,
    customer,
    dashboard,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;