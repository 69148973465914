import { createContext, useState } from 'react';

interface ISignInModel {
    userId: string;
    password: string;
}

export interface IMemberContext {
    signInModel: ISignInModel | undefined;
    setSignInModel: (signInModel: ISignInModel) => void;
}

const defaultContext: IMemberContext = {
    signInModel: undefined,
    setSignInModel: () => { }
};

const MemberContext = createContext(defaultContext);

interface Props {
    children: JSX.Element | Array<JSX.Element>;
}

const MemberContextProvider = ({ children }: Props) => {
    const [signInModel, setSignInModel] = useState<ISignInModel | undefined>(undefined);

    return (
        <MemberContext.Provider
            value={{
                signInModel,
                setSignInModel,
            }}>
            {children}
        </MemberContext.Provider>
    );
};
export { MemberContextProvider, MemberContext };