import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import Test from '../pages/test';
import SalesByProduct from '../pages/salesByProduct';

// render - dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const Partner = Loadable(lazy(() => import('../pages/partner')));
const PositBot = Loadable(lazy(() => import('../pages/positbot')));
const KakaoPay = Loadable(lazy(() => import('../pages/kakaopay')));
const KakaoPaySuccess = Loadable(lazy(() => import('../pages/kakaopay/kakaopaySuccess')));
const KakaoPayCancel = Loadable(lazy(() => import('../pages/kakaopay/kakaopayCancel')));
const KakaoPaySuccessFail = Loadable(lazy(() => import('../pages/kakaopay/kakaopaySuccessFail')));
const KakaoAlimTalk = Loadable(lazy(() => import('../pages/kakaoAlimTalk')));
const Cafe24 = Loadable(lazy(() => import('../pages/cafe24')));
const NaverCommerce = Loadable(lazy(() => import('../pages/naverCommerce')));
const CreatePartner = Loadable(lazy(() => import('../pages/partner/createPartner')));
const DetailPartner = Loadable(lazy(() => import('../pages/partner/detailPartner')));
const KakaoLogin = Loadable(lazy(() => import('../pages/kakaoLogin')));
const KakaoLoginCallback = Loadable(lazy(() => import('../pages/kakaoLoginCallback')));
const PositbotHistory = Loadable(lazy(() => import('../pages/positbotHistory')));
const PositbotOrder = Loadable(lazy(() => import('../pages/positbotOrder')));
const PositbotBuyer = Loadable(lazy(() => import('../pages/positbotBuyer')));
const PositbotProduct = Loadable(lazy(() => import('../pages/positbotProduct')));
const PositbotReport = Loadable(lazy(() => import('../pages/positbotReport')));
const GoodsMapping = Loadable(lazy(() => import('../pages/goodsMapping')));
const NaverLogin = Loadable(lazy(() => import('../pages/naverLogin')));
const NaverLoginCallback = Loadable(lazy(() => import('../pages/naverLoginCallback')));
const BuyReport = Loadable(lazy(() => import('../pages/buyReport')));
const Customer = Loadable(lazy(() => import('../pages/customer')));
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('../pages/authentication/Register')));
const RowData = Loadable(lazy(() => import('../pages/v2')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        { path: '/', element: <Dashboard /> },
        { path: '/partner', element: <Partner /> },
        { path: '/positbot', element: <PositBot /> },
        { path: '/kakaopay', element: <KakaoPay /> },
        { path: '/kakaoPaySuccess', element: <KakaoPaySuccess /> },
        { path: '/kakaoPayCancel', element: <KakaoPayCancel /> },
        { path: '/kakaoPaySuccessFail', element: <KakaoPaySuccessFail /> },
        { path: '/kakaoalimtalk', element: <KakaoAlimTalk /> },
        { path: '/cafe24', element: <Cafe24 /> },
        { path: '/naverCommerce', element: <NaverCommerce /> },
        { path: '/partner/create', element: <CreatePartner /> },
        { path: '/partner/detail/:id', element: <DetailPartner /> },
        { path: '/kakaologin', element: <KakaoLogin /> },
        { path: '/naverlogin', element: <NaverLogin /> },
        { path: '/naverlogin/callback', element: <NaverLoginCallback /> },
        { path: '/kakaologin/callback', element: <KakaoLoginCallback /> },
        { path: '/positbot/history', element: <PositbotHistory /> },
        { path: '/positbot/order', element: <PositbotOrder /> },
        { path: '/positbot/buyer', element: <PositbotBuyer /> },
        { path: '/positbot/product', element: <PositbotProduct /> },
        { path: '/positbot/report', element: <PositbotReport /> },
        { path: '/goods-mapping', element: <GoodsMapping /> },
        { path: '/buy-report', element: <BuyReport /> },
        { path: '/positbot/customer', element: <Customer /> },
        { path: '/login', element: <AuthLogin /> },
        { path: '/register', element: <AuthRegister /> },
        { path: '/test', element: <Test /> },
        { path: '/v2', element: <RowData /> },
        { path: '/sales/product', element: <SalesByProduct /> },
    ]
};

export default MainRoutes;