import axios, { AxiosResponse } from 'axios'

export const requestUploadOtooDeliveryListData = (requestList: IOttooDeliveryRequest[]) => {
    return axios.post(`${process.env.REACT_APP_API_DOMAIN}/otoo/delivery/all`, requestList)
}

export const getOtooDeliveryChannelAndShipmentDateBetweenByDate = (startYearMonth: string, endYearMonth: string): Promise<AxiosResponse<IChannelAndShipmentDateBetween[], any>> => {
    return axios.get(`${process.env.REACT_APP_API_DOMAIN}/otoo/delivery/procedure/group-by/date?startYearMonth=${startYearMonth}&endYearMonth=${endYearMonth}`)
}

export const getOtooDeliveryShipmentDateBetweenByDay = (targetDailyTrend: IOtooDeliveryShipmentDateBetweenAndProductNameByDay): Promise<AxiosResponse<any[], any>> => {
    return axios.get(`${process.env.REACT_APP_API_DOMAIN}/otoo/delivery/procedure/group-by/day?targetYearMonth=${targetDailyTrend.targetYearMonth}&targetItemCode=${targetDailyTrend.itemCode}`)
}

export const requestUploadOtooProductListData = (requestList: IOttooProductRequest[]) => {
    return axios.post(`${process.env.REACT_APP_API_DOMAIN}/otoo/product/all`, requestList)
}